import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box, L2Hero, LocationList, Map, Ram, ResponsiveContext } from '../ui';
import locationIcon from '../images/location.svg';
import mapStyle from '../utils/mapstyle.json';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

const { GATSBY_GOOGLE_MAPS_API_KEY } = process.env;
const zoom = 5;
const center = {
  lat: 39.8097343,
  lng: -98.5556199,
};

const MapContatiner = styled(Box)`
  position: relative;
`;

export const query = graphql`
  {
    prismic {
      allLocationss {
        edges {
          node {
            meta_title
            meta_description
            hero_header
            hero_copy
            locations {
              address1
              address2
              city
              coordinates
              country
              fax
              name
              phone1
              phone2
              state
              zip_code
            }
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.allLocationss.edges[0].node;

  const coords = content.locations.map(({ coordinates }) => ({
    id: `${coordinates.latitude}${coordinates.longitude}`,
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  }));

  useEffect(() => {
    if (typeof window !== `undefined` && window.location.hash) {
      const { hash } = window.location;
      document.getElementById(hash).click();
    }
  }, []);

  return (
    <Layout title={content.meta_title} description={content.meta_description}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const isSmall = size === 'small';

          return (
            <>
              <L2Hero
                backgroundColor="CB361D"
                size={size}
                titleHead={content.hero_header}
                titleText={content.hero_copy}
                htmlSerializer={htmlSerializer}
              />
              <MapContatiner height={!isSmall && size}>
                {!isSmall && (
                  <Map
                    bootstrapURLKeys={{ key: GATSBY_GOOGLE_MAPS_API_KEY }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={{ styles: mapStyle }}
                  >
                    {coords &&
                      coords.map((item) => (
                        <img
                          alt=""
                          key={item.id}
                          lat={item.lat}
                          lng={item.lng}
                          src={locationIcon}
                          style={{ transform: 'translate(-50%, -100%)' }}
                        />
                      ))}
                  </Map>
                )}
              </MapContatiner>
              <LocationList locations={content.locations} coords={coords} />
              <Ram color="CB361D" />
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
